<template>
  <div class="storageInventory">
    <h1>库存及成本查询</h1>
    <el-form :inline="true">
      <el-form-item label="日期">
        <el-date-picker :clearable="false" v-model="searchParam.date" type="date" style="width: 130px;" />
      </el-form-item>
      <el-form-item label="仓储">
        <el-select v-model="searchParam.storage" filterable style="width: 120px;" clearable>
          <el-option v-for='storageItem in show.storageList' :key="storageItem.id" :label="storageItem.value" :value="storageItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="煤矿品名">
        <el-select v-model="searchParam.product" filterable style="width: 120px;" clearable>
          <el-option v-for='productItem in show.productList' :key="productItem.id" :label="productItem.value" :value="productItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hasStorage" active-text="区分仓储" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" :span-method="handleSpanMethod" style="height: calc(100vh - 310px);">
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.type == 'sumStorage'" style="font-weight: bolder;">合计</el-text>
          <el-text v-else>{{ scope.row.storage }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="product" label="煤矿品名" show-overflow-tooltip />
      <el-table-column prop="quantity" label="库存量" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text v-if="scope.row.type == 'sumStorage'" style="font-weight: bolder;">{{ math.formatNumber(scope.row.quantity) }}</el-text>
          <el-text v-else>{{ math.formatNumber(scope.row.quantity) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="productPrice" label="单价" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text v-if="scope.row.type == 'sumStorage'" style="font-weight: bolder;">{{ math.formatNumber(scope.row.productPrice) }}</el-text>
          <el-text v-else>{{ math.formatNumber(scope.row.productPrice) }}</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="transportPrice" label="运费单价" show-overflow-tooltip align="right">
        <template #default="scope">
          <el-text v-if="scope.row.type == 'sumStorage'" style="font-weight: bolder;">{{ math.formatNumber(scope.row.transportPrice) }}</el-text>
          <el-text v-else>{{ math.formatNumber(scope.row.transportPrice) }}</el-text>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'
import math from '../../utils/math'

const router = useRouter()

const data = reactive({
  list: [],
})

const show = reactive({
  storageList: [],
  productList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'storage' } }).then(res => {
  show.storageList = res.dictList
})
api.get('/backend/dict/getByCode', { params: { code: 'product' } }).then(res => {
  show.productList = res.dictList
})

const now = new Date()
const yesterday = new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000)

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.date = yesterday
  searchParam.storage = null
  searchParam.product = null
  searchParam.hasStorage = true
}
resetSearchParam()

const paramsList = []
const commitFindList = () => {
  if (!searchParam.date) {
    ElMessage.error('请选择日期')
    return
  }
  data.list = []
  const date = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  for (const storageIndex in show.storageList) {
    const storage = show.storageList[storageIndex].value
    if (searchParam.storage == null || searchParam.storage == storage) {
      for (const productIndex in show.productList) {
        const product = show.productList[productIndex].value
        if (searchParam.product == null || searchParam.product == product) {
          const params = { date, storage, product }
          paramsList.push(params)
        }
      }
    }
    if (searchParam.hasStorage) {
      paramsList.push({ type: 'sumStorage', storage: storage })
    }
  }
  commitFindItem()
}

const commitFindItem = () => {
  if (paramsList.length <= 0) {
    if (!searchParam.hasStorage) {
      const rlist = []
      for (const rowIndex in data.list) {
        const oldRow = data.list[rowIndex]
        const sameProductRow = rlist.filter(newRow => newRow.product == oldRow.product)
        if (sameProductRow.length <= 0) {
          rlist.push(oldRow)
        } else {
          sameProductRow[0].quantity = math.plus(sameProductRow[0].quantity, oldRow.quantity)
          sameProductRow[0].productAmount = math.plus(sameProductRow[0].productAmount, oldRow.productAmount)
          sameProductRow[0].transportAmount = math.plus(sameProductRow[0].transportAmount, oldRow.transportAmount)
        }
      }
      for (const rowIndex in rlist) {
        const row = rlist[rowIndex]
        row.productPrice = row.quantity == 0 ? 0 : math.halfUp(row.productAmount / row.quantity, 2)
        row.transportPrice = row.quantity == 0 ? 0 : math.halfUp(row.transportAmount / row.quantity, 2)
        row.storage = ''
      }
      data.list = rlist
    }
    return
  }
  const params = paramsList.shift()
  if (params.type == 'sumStorage') {
    let sumQuantity = 0
    let sumProductAmount = 0
    let sumTransportAmount = 0
    data.list.filter(row => row.storage == params.storage).forEach(row => {
      sumQuantity = math.plus(sumQuantity, row.quantity)
      sumProductAmount = math.plus(sumProductAmount, row.productAmount)
      sumTransportAmount = math.plus(sumTransportAmount, row.transportAmount)
    })
    data.list.push({
      type: 'sumStorage',
      quantity: sumQuantity,
      productPrice: sumQuantity == 0 ? 0 : math.halfUp(sumProductAmount / sumQuantity, 2),
      transportPrice: sumQuantity == 0 ? 0 : math.halfUp(sumTransportAmount / sumQuantity, 2),
    })
    commitFindItem()
  } else {
    api.get('/backend/inventory/info', { params: params }).then(res => {
      if (res.quantity) {
        const row = {
          storage: params.storage,
          product: params.product,
          quantity: res.quantity,
          productPrice: res.productPrice,
          transportPrice: res.transportPrice,
          productAmount: res.productAmount,
          transportAmount: res.transportAmount,
        }
        data.list.push(row)
      }
      commitFindItem()
    })
  }
}

const summary = () => {
  let sumQuantity = 0
  let sumProductAmount = 0
  let sumTransportAmount = 0
  data.list.forEach(row => {
    if (row.type != 'sumStorage') {
      sumQuantity = math.plus(sumQuantity, row.quantity)
      sumProductAmount = math.plus(sumProductAmount, row.productAmount)
      sumTransportAmount = math.plus(sumTransportAmount, row.transportAmount)
    }
  })
  const sumProductPrice = sumQuantity == 0 ? 0 : math.halfUp(sumProductAmount / sumQuantity, 2)
  const sumTransportPrice = sumQuantity == 0 ? 0 : math.halfUp(sumTransportAmount / sumQuantity, 2)
  return ['合计', '', math.formatNumber(sumQuantity), math.formatNumber(sumProductPrice), math.formatNumber(sumTransportPrice)]
}

const handleSpanMethod = ({ rowIndex, columnIndex }) => {
  const columnIndexs = [0] // 合并
  if (!columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  const row = data.list[rowIndex]
  if (rowIndex > 0) {
    const lastrow = data.list[rowIndex - 1]
    if (columnIndex == 0 && row.storage == lastrow.storage) {
      return [0, 0]
    }
  }
  let span
  for (span = 1; rowIndex + span < data.list.length; span++) {
    const nextrow = data.list[rowIndex + span]
    if (columnIndex == 0 && row.storage != nextrow.storage) {
      break
    }
  }
  return [span, 1]
}
</script>

<style lang="less"></style>